import React from 'react';
import Checkout from '../../../components/Checkout/index';
import ContextProvider from '../../../components/ContextProvider';
import PrivateRoute from '../../../components/PrivateRoute';

const CheckoutPageEnAu = () => {
  return (
    <ContextProvider localeCode="en-AU">
      <PrivateRoute component={Checkout} verifiedEmailRequired />
    </ContextProvider>
  );
};

export default CheckoutPageEnAu;
